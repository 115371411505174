//-------Custom CSS Starts here-------------//
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Raleway');
@import "custom/home";
// @import "custom/portfolio";
// @import "custom/project";
// @import "custom/about";
// @import "custom/contact";
// @import "custom/blog";
//@import "custom/shop";
@import "custom/navbar";
@import "custom/mediaqueries";
