/*scroll button position querry*/
 @media only screen and (max-height: 675px) {
  .scrollButtonA {
    margin-top: 10vh;
  }
}

 @media only screen and (min-height: 675px) {
  .scrollButtonA {
    margin-top: 30vh;
  }
}

@media  (max-width: 768px) {
  .hideNavbarElement{
    display: none;
}
}

@media only screen and (max-width: 768px) {
  .video-wrapper  {
      overflow:visible !important;
}
}
