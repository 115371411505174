.navbar {
  text-transform: uppercase;
  margin-bottom: 0px;
}
.navbar-dark {
  width: 100%;
}
.navbar-dark {
  background-color: #329eaa;
}

.navbar-dark .navbar-nav .nav-item .nav-link {
  color: white;
  padding-top: 25px;
  padding-bottom: 25px;
}
.navbar-dark .navbar-nav .nav-item .nav-link:hover {
  color: #222222;
}

.navbar-brand {
  height: 70px;
  padding: 0px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 2px;
  margin-right: -10px;
}
.navbar-brand > img {
height: 100%;
/*padding: 15px;*/
width: auto;
margin-left:15px;
margin-right:25px;
}
.navbar-dark .navbar-brand {
  color: white;
}
.navbar-dark .navbar-toggler {
  border-color: white;
}

.brand-text {
  border-top-style: solid;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-top-width: 1px;
}
.branding {
  display: flex;
  align-self: center;
}
